import React from 'react';
import { motion } from 'framer-motion';

const CertificareCard = ({ imageSrc, number }) => {
  return (
    <motion.div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "20px 20px",
        cursor: "default", // Remove cursor change on hover
      }}
      whileHover={{
        rotate: [0, 5, -5, 5, -5, 0], 
        scale: 1.05, // Slight scale on hover for the whole card
        transition: { duration: 0.6, ease: "easeInOut" },
      }}
      animate={{
        rotate: 0, // Reset rotation when not hovered
        scale: 1, // Reset scale when not hovered
      }}
    >
      <motion.img
        src={`${process.env.REACT_APP_SERVER_Local}/uploads/certificate/${imageSrc}`} 
        alt=""
        style={{
          width: "200px",
          height: "220px",
          marginBottom: "20px",
          marginTop: "40px",
          cursor: "default", // Remove cursor change on hover
        }}
        whileHover={{
          scale: 1.2, // Slightly scale the image on hover
        }}
        animate={{
          scale: 1, // Ensure image resets to default scale when not hovered
        }}
      />
      <h3
        style={{
          border: "2px solid green",
          padding: "10px",
          width: "400px",
          textAlign: "center",
        }}
      >
        {number}
      </h3>
    </motion.div>
  );
};

export default CertificareCard;
