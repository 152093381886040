import React from "react";
import localBusiness from "../assets/images/localBusiness.png";
import imageback from "../assets/images/imageback.jpg";
import { FaCheck } from "react-icons/fa";
import Header from "../component/header/Header";
import { LeafTitle } from "../component/common/LeafTitle";
import { motion } from "framer-motion";
import checkicon from "../assets/images/icons/check.png";

const whylocal = [
  {
    title: "Empowering Local Farmers",
    description:
      "Our strength lies in our close relationships with over 2,000 Bangladeshi farmers. By working directly with them, we ensure the highest quality products reach the market while supporting local communities and promoting sustainable farming practices.",
  },
  {
    title: "Extensive Product Range for Domestic Markets",
    description:
      "We offer a broad selection of agricultural products tailored to meet the needs of businesses and retailers within Bangladesh, including fresh vegetables, eggs, chicken, livestock feed, and seasonal mangoes.",
  },
  {
    title: "Competitive Pricing, Quality Assurance",
    description:
      "Our local trading model ensures cost efficiency through direct sourcing, allowing us to offer competitive prices without compromising on quality. We strive to make premium agricultural products accessible to businesses and consumers throughout Bangladesh.",
  },
  {
    title: "A Nationwide Distribution Network",
    description:
      "Farmvaly Bangladesh operates a widespread distribution network across the country. Our local presence ensures timely deliveries, reduces logistics costs, and provides exceptional service to customers in both urban and rural areas.",
  },
  {
    title: "Quality Assurance, Even Locally",
    description:
      "We uphold strict quality control standards. Every product is carefully inspected to meet our high-quality benchmarks, ensuring that our customers receive only the best.",
  },
  {
    title: "Empowering Bangladesh's Agricultural Economy",
    description:
      "Our local trading operations support farmers by providing fair trade opportunities and connecting them with the broader market, contributing to the growth and prosperity of Bangladesh's agricultural sector.",
  },
];

const LocalTrading = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />
      <div className="container">
        <div className="market-container">
          <div className="image-container">
            <img src={localBusiness} alt="Agriculture Image 1" className="image1" />
            <img src={imageback} alt="Agriculture Image 2" className="image2" />
          </div>
          <div className="business-content-container">
            <h1>Local Trading</h1>

            <p>
              Farmvaly Bangladesh is proud to be a trusted name in Bangladesh's
              domestic agricultural market. With deep roots in local farming
              communities, we bring high-quality agricultural products directly
              to businesses and consumers nationwide. By connecting local
              farmers with the market, we ensure fresh, premium products are
              available to meet the diverse needs of our customers.
            </p>
            <ul className="business-feature-list">
              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Vegetable Items:
                </span>{" "}
                Seeds, fish, dry fish, and fresh harvesting items (7,000 KG of
                vegetable production and selling to wholesalers and retailers to
                meet community demand).
              </li>
              <br />

              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Eggs:
                </span>{" "}
                1.5 million egg production and selling to retail and wholesale
                to meet local demand.
              </li>
              <br />

              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Chicken:
                </span>{" "}
                50,000 units of chicken production fulfilling community protein
                demand through wholesalers and retailers.
              </li>
              <br />

              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Livestock Feed:
                </span>{" "}
                20 Metric Tons of livestock feed manufactured to meet own demand
                and supply to the surrounding network, with ongoing expansion
                each month.
              </li>
              <br />

              <li>
                <img src={checkicon} alt="" className="checkIconExport" />
                <span style={{ fontWeight: "bold", display: "inline" }}>
                  Mango:
                </span>{" "}
                Fresh, high-quality seasonal mangoes delivered directly from the
                farm during their peak harvest period.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <LeafTitle title={"Why Farmvaly for Local Trading?"} />

      <div className="brand-personality-area ptb--60 bg_color--5">
        <div className="container">
          <div className="row brand-personality-wrapper">
            {whylocal.map((val, i) => (
              <motion.div
                initial={{ y: 90, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0, duration: 0.5 }}
                className="brandCARD col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                key={i}
              >
                <div className="brand-personality brand-personality__style--2">
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h2>Contact Us today</h2>
                <a className="rn-button-style--2" href="/contact">
                  <span>Contact Us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocalTrading;
