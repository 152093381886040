import React from "react";
import Header from "../component/header/Header";

const Certifications = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />
    </>
  );
};

export default Certifications;
