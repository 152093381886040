import React, { useState, useEffect } from "react";
import PageHelmet from "../component/common/Helmet";
import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { Link, useParams } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import { ArticleList } from "../data/ArticleData";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "react-share";
import { fetchArticleById, fetchArticles } from "../data/dataFetcher";
import DOMPurify from "dompurify";

const ArticleDetails = () => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [article, setArticle] = useState(null);
  const [articleList, setArticleList] = useState([]);

  useEffect(() => {
    const getAllArticles = async () => {
      try {
        const allArticles = await fetchArticles();
        setArticleList(allArticles);
      } catch (err) {
        console.error("Error fetching the Articles:", err);
      }
    };
    const loadArticle = async () => {
      try {
        const articleData = await fetchArticleById(id);
        setArticle(articleData);
      } catch (err) {
        console.error("Error fetching the article:", err);
      }
    };

    loadArticle();
    getAllArticles();
  }, [id]);

  const openModal = () => {
    setIsOpen(true);
  };

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <PageHelmet pageTitle="Article Details" />
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />
      <div
        className="article-details__hero-section pt--120 pb--190"
        style={{
          backgroundImage: `url(${process.env.REACT_APP_SERVER_Local}/uploads/articles/${article.image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 1,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="article-details__title-wrapper text-center pt--100"
                style={{
                  position: "relative",
                  zIndex: 2,
                }}
              >
                <h2 className="">{article.title}</h2>
           
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Start Article Details Section */}
      <div className="article-details__content-section  bg_color--1">
        <div className="container">
          <div className="row">
            {/* Article Content Area */}
            <div className="col-lg-8">
              <div className="article-details__content">
                <p>{article.content}</p>
                <div className="article-details__thumbnail">
                  <img
                    src={`${process.env.REACT_APP_SERVER_Local}/uploads/articles/${article.image}`}
                    alt="Blog Images"
                  />
                </div>
                <p
                  className="mt--40"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(article.details),
                  }}
                ></p>
              </div>

              {/* Start Article Comment Form */}
              <div className="article-details__comment-form pb--120 bg_color--1">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="inner">
                        <h3 className="article-details__comment-title mb--40 fontWeight500">
                          Leave a Comment
                        </h3>
                        <form>
                          <div className="row">
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <input type="text" placeholder="Full Name" />
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <input
                                  type="email"
                                  placeholder="Email Address"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-12 col-md-12">
                              <div className="form-group">
                                <textarea placeholder="Write Comment"></textarea>
                              </div>
                            </div>
                            <div className="col-lg-12">
                              <button className="btn btn-primary">
                                Post Comment
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End Article Comment Form */}
            </div>

            {/* Sidebar */}
            <div className="col-lg-4">
              <aside className="article-details__sidebar">
                {/* Recent Posts */}
                <div className="article-details__recent-posts-widget">
                  <h4>Latest Posts</h4>
                  <ul>
                    {articleList &&
                      articleList.slice(0, 3).map((item) => (
                        <li
                          key={item.id}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "15px",
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_SERVER_Local}/uploads/articles/${item.image}`}
                            alt={item.title}
                            style={{
                              width: "50px",
                              height: "50px",
                              objectFit: "cover",
                              marginRight: "10px",
                              borderRadius: "5px",
                            }}
                          />
                          <Link
                            to={`/article-details/${item.id}`}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            {item.title}
                          </Link>
                        </li>
                      ))}
                  </ul>
                </div>

                {/* Categories */}
                <div className="article-details__categories-widget">
                  <h4>Category</h4>
                  <ul>
                    <li>
                      <Link to="#">{article.category}</Link>
                    </li>
                  </ul>
                </div>

                {/* Tags */}
                <div className="article-details__tags-widget">
                  <h4>Tags</h4>
                  <ul className="tags">
                    <li>
                      <Link to="#">Harvest</Link>
                    </li>
                    <li>
                      <Link to="#">Vegetables</Link>
                    </li>
                    <li>
                      <Link to="#">Organic</Link>
                    </li>
                  </ul>
                </div>

                {/* Share */}
                <div className="article-share-buttons">
                  <h4>Social Share</h4>

                  <a
                    href={`http://www.facebook.com/sharer/sharer.php?u=http://farmvaly.com/article-details/${article.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "5px" }}
                  >
                    <FacebookIcon size={32} round={true} />
                  </a>

                  <a
                    href={`https://twitter.com/intent/tweet?url=http://farmvaly.com/article-details/${article.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginRight: "5px" }}
                  >
                    <TwitterIcon size={32} round />
                  </a>

                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=http://farmvaly.com/article-details/${article.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedinIcon size={32} round />
                  </a>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </div>
      {/* End Article Details Section */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </>
  );
};

export default ArticleDetails;
