const BASE_URL = process.env.REACT_APP_SERVER_Local;

export const fetchArticleById = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/articles/${id}`);
    if (!response.ok) {
      throw new Error("Failed to fetch article");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching article:", error);
    throw error;
  }
};

export const fetchArticles = async () => {
  try {
    const response = await fetch(`${BASE_URL}/articles`);
    if (!response.ok) {
      throw new Error("Failed to fetch articles");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching articles:", error);
    throw error;
  }
};

export const fetchMembers = async () => {
  try {
    const response = await fetch(`${BASE_URL}/team`);
    if (!response.ok) {
      throw new Error("Failed to fetch members");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching members:", error);
    throw error;
  }
};
export const fetchCertificate = async () => {
  try {
    const response = await fetch(`${BASE_URL}/certificate`);
    if (!response.ok) {
      throw new Error("Failed to fetch certificate");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching certificate:", error);
    throw error;
  }
};

export const fetchNews = async () => {
  try {
    const response = await fetch(`${BASE_URL}/news`);
    if (!response.ok) {
      throw new Error("Failed to fetch news");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching news:", error);
    throw error;
  }
};

export const fetchNewsById = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/news/${id}`);
    if (!response.ok) {
      throw new Error("Failed to fetch news");
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Error fetching news:", error);
    throw error;
  }
};
