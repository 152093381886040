import React, { Component } from "react";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import { LeafTitle } from "../component/common/LeafTitle";
import { motion } from "framer-motion";
import authentic from "../../src/assets/images/authenticity.png";
import innovation from "../../src/assets/images/innovation.png";
import trust from "../../src/assets/images/agreement.png";
import protest from "../../src/assets/images/protest.png";
import sustainable from "../../src/assets/images/save-the-world.png";
import world from "../../src/assets/images/world.png";

const BrandPersonalityList = [
  {
    icon: authentic,
    title: "Authentic",
    description:
      "Deeply rooted in Bangladesh’s rich agricultural heritage, is genuinely committed to quality, tradition, and community.",
  },
  {
    icon: innovation,
    title: "Innovative",
    description:
      "While honoring tradition, Farmvaly embraces innovation to continuously improve and adapt to global market demands.",
  },
  {
    icon: trust,
    title: "Trustworthy",
    description:
      "Farmvaly upholds integrity and reliability, ensuring that every product meets the highest standards and every promise is fulfilled.",
  },
  {
    icon: protest,
    title: "Empowering",
    description:
      "Farmvaly supports the local farming community by providing them with the tools, resources, and opportunities to thrive.",
  },
  {
    icon: sustainable,
    title: "Sustainable",
    description:
      "Farmvaly is dedicated to ethical and environmentally-friendly practices, prioritizing the long-term well-being of both the planet and its people.",
  },
  {
    icon: world,
    title: "Global-Minded",
    description:
      "With a vision that transcends borders, Farmvaly connects Bangladesh’s agricultural excellence with the world, embracing cultural diversity and international collaboration.",
  },
];

class BrandPersonality extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="maindivbrandpersonality">
          <LeafTitle title={"Our core values"} />
          {/* Start Brand Personality Area */}
          <div className="brand-personality-area ptb--60 bg_color--5">
            <div className="container">
              <div className="row brand-personality-wrapper">
                {BrandPersonalityList.map((val, i) => (
                  <motion.div
                    initial={{ y: 90, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0, duration: 0.5 }}
                    className="brandCARD col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                    key={i}
                  >
                    <div className="brand-personality brand-personality__style--2">
                      <div className="content">
                        <div>
                          <motion.img
                            src={val.icon}
                            alt="icon"
                            style={{ maxWidth: "60px", marginBottom: "20px" }}
                            initial={{ rotateY: 0, scale: 0.8 }}
                            whileInView={{
                              rotateY: 360,
                              opacity: 1,
                              scale: 1,
                            }}
                            transition={{
                              rotateY: { duration: 2, ease: "easeInOut" },
                              scale: { type: "spring", stiffness: 100, damping: 20 },
                            }}
                          />
                          <h3 className="title">{val.title}</h3>
                        </div>

                        <p>{val.description}</p>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
          {/* End Brand Personality Area */}

          {/* Start Back To Top */}
          <div className="backto-top">
            <ScrollToTop showUnder={160}>
              <FiChevronUp />
            </ScrollToTop>
          </div>
          {/* End Back To Top */}
        </div>
      </React.Fragment>
    );
  }
}

export default BrandPersonality;
