import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/ataglance.css";
import c1 from "../../assets/images/ataglance/c1.webp";
import c2 from "../../assets/images/ataglance/c2.jpg";
import n1 from "../../assets/images/ataglance/n1.jpg";
import n2 from "../../assets/images/ataglance/n2.jpg";
import h1 from "../../assets/images/ataglance/h1.jpg";
import a1 from "../../assets/images/ataglance/a1.jpg";
import ed1 from "../../assets/images/ataglance/ed1.jpg";
import e1 from "../../assets/images/ataglance/e1.jpeg";
import s1 from "../../assets/images/ataglance/s1.jpg";
import p1 from "../../assets/images/ataglance/p1.webp";

// Set the root element for the modal
Modal.setAppElement("#root");

// Define categories with images and stories
const categories = [
  {
    title: "Cultural Heritage",
    image: c1,
    additionalImages: [
      { src: c2, description: "Cultural heritage example 1" },
    ],
    story: "Explore the rich cultural heritage through these captivating images.",
  },
  {
    title: "Tourism and Natural Beauty",
    image: n1,
    additionalImages: [
      { src: n2, description: "Beautiful landscape view" },
    ],
    story: "Discover the breathtaking landscapes and natural wonders.",
  },
  {
    title: "Cuisine",
    image: h1,
    additionalImages: [
      { src: a1, description: "Delicious cuisine" },
    ],
    story: "Savor the flavors of diverse cuisines from around the world.",
  },
  {
    title: "Economic",
    image: ed1,
    additionalImages: [
      { src: e1, description: "Economic development image" },
    ],
    story: "Gain insights into economic development through thought-provoking images.",
  },
  {
    title: "Education",
    image: s1,
    additionalImages: [
      { src: p1, description: "Education advancement" },
    ],
    story: "Learn about educational advancements through engaging images.",
  },
  {
    title: "Sports",
    image: p1,
    additionalImages: [
      { src: c1, description: "Sports action shot 1" },
      { src: n1, description: "Sports action shot 2" },
    ],
    story: "Immerse yourself in the world of sports with dynamic images.",
  },
  {
    title: "Social Progress",
    image: n2,
    additionalImages: [
      { src: h1, description: "Social progress image" },
    ],
    story: "Explore strides in social progress through insightful images.",
  },
  {
    title: "Architecture and Infrastructure",
    image: a1,
    additionalImages: [
      { src: ed1, description: "Architectural marvel" },
    ],
    story: "Admire architectural marvels and infrastructure projects.",
  },
  {
    title: "Pop Culture",
    image: e1,
    additionalImages: [
      { src: s1, description: "Pop culture event" },
    ],
    story: "Dive into the vibrant world of pop culture with engaging images.",
  },
  {
    title: "Quality of Life",
    image: c2,
    additionalImages: [
      { src: p1, description: "Quality of life image" },
    ],
    story: "Understand factors contributing to a high quality of life through compelling images.",
  },
];

const AtaGlance = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);

  // Open the modal with the selected category
  const openModal = (category) => {
    setCurrentCategory(category);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentCategory(null);
  };

  // Settings for the Slick slider
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000,
  };

  return (
    <div className="ataglance-image-grid container">
      <div className="row">
        {categories.map((category, index) => (
          <div key={index} className="col-lg-4 col-md-12 mb-4 mb-lg-0">
            <div className="category-image-container">
              <img
                src={category.image}
                className="category-image"
                alt={category.title}
                onClick={() => openModal(category)}
              />
              <div className="category-title">{category.title}</div>
            </div>
          </div>
        ))}
      </div>

      {/* Modal displaying the current category */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Category Modal"
        className="category-modal"
        overlayClassName="category-overlay"
      >
        <button className="close-modal-btn" onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {currentCategory && (
          <div className="ataglancepopup-main">
            <div className="left-panel">
              <img
                src={currentCategory.image}
                alt={currentCategory.title}
                className="ataglancepopup-image"
              />
              <h2 className="ataglancepopup-title">{currentCategory.title}</h2>
              <p className="description">{currentCategory.story}</p>
            </div>
            <div className="right-panel">
              <Slider {...sliderSettings} className="ataglance-slider">
                {[currentCategory.image, ...(currentCategory.additionalImages || [])].map((item, index) => {
                  const imageSrc = typeof item === 'string' ? item : item.src; // Handle both image strings and objects
                  const description = typeof item === 'string' ? '' : item.description; // Get the description if available

                  return (
                    <div key={index} className="slider-item">
                      <div className="image-container">
                        <img
                          src={imageSrc}
                          alt={`Slide ${index + 1}`}
                          className="ataglancepopup-slider-image"
                        />
                        <div className="ataglace-slider-image-text">{description ||currentCategory.title }</div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default AtaGlance;
