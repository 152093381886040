import React, { Component } from "react";

import brand1 from "../assets/images/logo/farmvaly.jpg";


class BrandTwo extends Component {
  render() {
    return (
      <React.Fragment>
        <ul className="brand-style-2">
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
          <li>
            <img src={brand1} alt="Logo Images" />
          </li>
        </ul>
      </React.Fragment>
    );
  }
}
export default BrandTwo;
