import React from "react";
import Header from "../component/header/Header";
import SliderImage from "./../component/slider/SliderImage";
import Divider from "../component/common/Divider";
import { LeafTitle } from "../component/common/LeafTitle";

const TermsConditions = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />

      <div className="termsconditionsWrapper">
        <LeafTitle title={"Terms & Condition"} />
        <Divider />
        <div className="terms-container">
          <section>
            <p style={{ fontWeight: "bold" }}>Terms of Use</p>
            <p>
              Welcome to Farmvaly Bangladesh. Farmvaly Bangladesh (“Farmvaly
              Bangladesh”) provides access to the Farmvaly Bangladesh website or
              the mobile application/app or any other media (“Website”) to you
              subject to the terms and conditions (“Terms”) set forth on this
              page. By using the Website, you, a registered or guest user in
              terms of the eligibility criteria set forth herein (“User”), agree
              to be bound by these Terms. Please read them carefully, as your
              continued use of the Website signifies your agreement to be bound
              by these Terms. If you do not want to be bound by the Terms, you
              must not subscribe to or use the Website or our services.
            </p>
            <p>
              By impliedly or expressly accepting these Terms, you also accept
              and agree to be bound by the Farmvaly Bangladesh Policies
              (including but not limited to the Privacy Policy available at
              https://Farmvaly Bangladesh/t/PrivacyInfo as amended from time to
              time).
            </p>
            <p>
              The contents set forth herein constitute an electronic record in
              terms of the Information & Communication Technology Act, 2006, and
              its rules as applicable and amended from time to time. As such,
              this document does not require any physical or digital signatures
              and forms a valid and binding agreement between the Website and
              the User.
            </p>
            <p>
              The Website is operated by Farmvaly Bangladesh Limited, a company
              incorporated under the laws of Bangladesh with its registered
              office at Dhaka, Bangladesh.
            </p>
            <p>
              We reserve the right to modify these Terms at any time. Changes
              will take effect upon posting on the Website. By continuing to use
              the Website after such changes are posted, you agree to the
              modified Terms.
            </p>
            <p>
              These Terms shall remain in effect until terminated by either you
              or Farmvaly Bangladesh in accordance with the provisions outlined
              below.
            </p>
            <p>
              The agreement with Farmvaly Bangladesh may be terminated by either
              party through the following actions: (i) by refraining from
              accessing the Website; or (ii) by closing your Account, if such an
              option is available to you.
            </p>
            <p>
              Provisions in these Terms that are intended to survive termination
              shall remain in effect following the termination or expiration of
              this agreement.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>User Eligibility</p>
            <p>
              Use of the Website is restricted to individuals who can legally
              contract under the Contract Act, 1872. If you are a minor (under
              the age of 18), you are prohibited from registering as a user of
              Farmvaly Bangladesh or transacting on the Website. Minors wishing
              to use or transact on the Website must do so through a legal
              guardian or parent.
            </p>
            <p>
              By accepting these Terms or using the Website, you irrevocably
              declare and warrant that you are at least 18 years old and capable
              of entering into a binding contract. Such usage constitutes a
              contract between you and the Website, to the extent permitted by
              applicable law.
            </p>
            <p>
              Services offered through the Website/App are available only for
              delivery addresses within Bangladesh. Users may specify a
              preferred delivery time slot, and Farmvaly Bangladesh will attempt
              to deliver during that time. However, delivery times provided are
              for general guidance only, and Farmvaly Bangladesh shall not be
              liable for delays caused by circumstances beyond its reasonable
              control.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Communication</p>
            <p>
              By using Farmvaly Bangladesh or contacting us via phone or email,
              you consent to receive communications from us. You authorize us to
              communicate with you through email, SMS, phone calls, notices
              posted on the Website, APP, or any other mode of communication.
              For contractual purposes, you agree to receive communications—
              including transactional, promotional, and commercial
              messages—related to your use of the Website and your orders.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>
              User Account and Responsibilities
            </p>
            <p>
              Any individual may access the Website by either registering or
              using it as a guest. Guest users may not have access to all
              sections of the Website, including certain benefits and
              promotional offers, which are exclusively reserved for registered
              users. To register an account on the Website, you must create an
              account using a valid Bangladeshi mobile number, Social media
              account, or email address.
            </p>
            <p>
              You are responsible for maintaining the confidentiality of your
              username and password and for all activities that occur under your
              account. You should notify us immediately if you suspect that your
              password has been compromised or is being used without
              authorization.
            </p>
            <p>
              The Website may be temporarily inaccessible for reasons deemed
              necessary by Farmvaly Bangladesh, including regular maintenance.
              Under no circumstances shall Farmvaly Bangladesh be liable for any
              losses or claims arising from such inaccessibility, and users
              expressly waive any claims against Farmvaly Bangladesh in this
              regard.
            </p>

            <p>
              Farmvaly Bangladesh does not support or encourage multiple
              accounts owned by a single user, even with different mobile
              numbers, and reserves the right to restrict, deactivate, or
              disable any account that is suspected of abusing the service.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Copyright</p>
            <p>
              The material, including content, software, and services, on
              Farmvaly Bangladesh is the property of Farmvaly Bangladesh and its
              subsidiaries, affiliates, and third-party licensors. No material
              on this site may be copied, reproduced, republished, installed,
              posted, transmitted, stored, or distributed without written
              permission.
            </p>
            <p>
              You may not use any "deep-link," "page-scrape," "robot," "spider,"
              or other automatic devices, programs, algorithms, or
              methodologies, or any similar manual processes to access, acquire,
              copy, or monitor any portion of the Website or its content.
              Additionally, you may not reproduce or circumvent the navigational
              structure or presentation of the Website or its content to obtain
              or attempt to obtain any materials, documents, or information
              through means not intentionally made available through the
              Website. We reserve the right to prohibit any such activities.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Internet Cookies</p>
            <p>
              This site uses cookies, which means you must have cookies enabled
              on your computer for all functionalities to work properly. Cookies
              contain information that helps track the pages you visit.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Promotional Activity</p>
            <p>
              Farmvaly Bangladesh promotes its services through various
              advertisements and commercials, which are truthful and not
              deceptive or unfair to the best of our knowledge. Users are
              obligated to review the relevant information on the Website before
              utilizing the services, and it will be assumed that each user is
              aware of all information provided. Images of products are for
              reference only, and actual products may vary from the displayed
              images. The Website disclaims any liability for discrepancies to
              the fullest extent permitted by law.
            </p>

            <p>
              Any promotional activities or offers, whether short-term or
              long-term, are valid for a single order within the specified time
              frame under a specific account on Farmvaly Bangladesh. Repetitive,
              suspicious, or abusive practices during promotions may result in
              canceled orders, and Farmvaly Bangladesh reserves the right to
              take such actions to protect the rights and benefits of other
              users. Accounts engaging in abuse of offers may be terminated to
              maintain platform integrity. Farmvaly Bangladesh may enforce
              automated abuse detection technology at any time without prior
              notice.
            </p>

            <p>
              Discount codes offered to customers through various marketing
              channels may be disabled or extended by the authority at any time
              without prior notice. The terms may also be adjusted in response
              to price changes or fluctuations and to prevent abuse or
              suspicious usage.
            </p>

            <p>
              Farmvaly Bangladesh reserves the right to withdraw, amend, or
              alter any applicable terms and conditions of discounts, coupons,
              deals, offers, or promotions at its sole discretion without prior
              notice.
            </p>

            <p>
              Farmvaly Bangladesh may exclude, void, discontinue, or disqualify
              any user from participating in discounts, coupons, deals, offers,
              or promotions at its sole discretion without prior notice.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Membership Fee</p>
            <p>
              Membership on the Website is free for users, and Farmvaly
              Bangladesh does not charge any fees for browsing or purchasing on
              the Website. However, Farmvaly Bangladesh reserves the right to
              modify its fee policy at any time.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Product Descriptions</p>
            <p>
              Farmvaly Bangladesh strives for accuracy in its product
              descriptions and content; however, Farmvaly Bangladesh does not
              guarantee that product descriptions or other content related to
              its products are accurate or error-free.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Return & Refund Policy</p>
            <p>
              A user may return any product at the time of delivery or within 7
              days if the product does not meet their expectations. For
              perishable products, we have an instant return policy. Refunds are
              processed within a maximum of 10 days.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Software Terms</p>
            <p>
              You may use the Farmvaly Bangladesh Software solely to enable and
              enjoy the services provided by Farmvaly Bangladesh. You are
              prohibited from incorporating any part of the software into your
              own programs or transferring it for use with another service.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>The Contract</p>
            <p>
              Your order constitutes an offer to purchase the product(s). When
              we send the Order Confirmation Email and/or SMS, a contract known
              as an “agreement to sell” is established.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Amend & Modifications</p>
            <p>
              Farmvaly Bangladesh reserves the right to modify these Terms and
              Conditions, with such modifications becoming effective immediately
              upon posting. It is your responsibility to review these
              modifications regularly.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Disclaimer</p>
            <p>
              The information provided in these Terms and Conditions is subject
              to change without prior notice. By using the Farmvaly Bangladesh
              Software, you acknowledge that you have read and agreed to these
              Terms and Conditions.
            </p>
          </section>

          <section>
            <p style={{ fontWeight: "bold" }}>Governing Law</p>
            <p>
              These Terms and Conditions shall be governed by and construed in
              accordance with the laws of Bangladesh.
            </p>
          </section>

          <p>
            If you have any questions about these Terms and Conditions, please
            contact us
            <a href="/contact">Contact</a>.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
