import React, { useEffect, useState, Fragment } from "react";
import ScrollToTop from "react-scroll-up";
import CallAction from "../elements/callaction/CallAction";
import { FiChevronUp } from "react-icons/fi";
import HeroSlider from "../component/slider/HeroSlider";
import WhatWeDo from "../component/HomeLayout/homeOne/WhatWeDo";
import { LeafTitle } from "../component/common/LeafTitle";
import ProductCard from "../component/product/ProductCard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { motion } from "framer-motion";
import herovideo from "../assets/images/hero_vid.mp4";
import CounterHome from "../elements/counters/CounterHome";
import Header from "../component/header/Header";
import { toast } from "react-toastify";

const SlideList = [
  {
    textPosition: "text-center",
    category: "",
    title: "Pure Excellence From Bangladesh",
    description:
      "Farmvaly Cultivating Bangladesh's Agricultural Bounty for the World.",
  },
  {
    textPosition: "text-center",
    category: "",
    title: "Global Reach Local Roots",
    description:
      "Farmvaly Cultivating Bangladesh's Agricultural Bounty for the World.",
  },
];

const FarmvalyHome = () => {
  const [featuredProducts, setFeaturedProducts] = useState([]);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_Local}/products`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const dataresponse = await response.json();
        const productsData = dataresponse.data;

        const featuredProductsOnly = productsData.filter(
          (product) => product.featured === 1
        );

        setFeaturedProducts(featuredProductsOnly);
      } catch (err) {
        toast.error(err.message);
      }
    };

    getProducts();
  }, []);

  const leftVariants = {
    offscreen: { x: -300, opacity: 0 },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.3, duration: 0.8 },
    },
  };

  const rightVariants = {
    offscreen: { x: 300, opacity: 0 },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: { type: "spring", bounce: 0.3, duration: 0.8 },
    },
  };

  return (
    <Fragment>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />

      {/* Start Slider Area */}
      <HeroSlider video={herovideo} slideList={SlideList} />

      <CounterHome />

      {/* Start What we do */}
      <WhatWeDo />

      {/* Start Product Showcase Area */}
      <div className="productShowcaseSection text-center">
        <LeafTitle title={"Our Products"} />

        <div className="container">
          <motion.div
            className="productShowcaseRowWraPPER row justify-between"
            initial={{ y: 90, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0, duration: 0.5 }}
          >
            {featuredProducts.map((value, index) => (
              <motion.div
                className="product-card-wrapper HOMEproduct-card-wrapper col-lg-3 col-md-4 col-sm-6"
                key={index}
                variants={index % 2 === 0 ? leftVariants : rightVariants}
              >
                <ProductCard product={value} />
              </motion.div>
            ))}
          </motion.div>

          <div className="moreProductBtnWrapper">
            <Link to="/products" className="moreProductBtn text-center">
              More Products
            </Link>
          </div>
        </div>
      </div>

      {/* End Product Showcase Area */}

      {/* Start call To Action */}
      <CallAction />

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </Fragment>
  );
};

export default FarmvalyHome;
