import React from "react";
import GoogleMapReact from "google-map-react";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones, FiMail, FiMapPin, FiChevronUp } from "react-icons/fi";

const ContactUs = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />
      <PageHelmet pageTitle="Contact Us" />
      {/* Start Contact Page Area  */}
      <div className="rn-contact-page ptb--120">
        <ContactTwo />
      </div>
      {/* End Contact Page Area  */}

      {/* Contact ROW  Area  */}
      <div className="rn-contact-top-area ptb--100 ">
        <div className="container">
          <div className="row">
            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rn-address">
                <div className="icon">
                  <FiHeadphones />
                </div>
                <div className="inner">
                  <h4 className="title"> Phone </h4>
                  <p>
                    <a href="">+880 1722 936722</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4 className="title">Email</h4>
                  <p>
                    <a href="">business@farmvaly.com</a>
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}

            {/* Start Single Address  */}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
              <div className="rn-address">
                <div className="icon">
                  <FiMapPin />
                </div>
                <div className="inner">
                  <h4 className="title">Registered Address</h4>
                  <p>
                    69/8, Mohammadia Plaza, Reazuddin Bazar,
                    Kotwali, Chattogram-4000
                  </p>
                </div>
              </div>
            </div>
            {/* End Single Address  */}
          </div>
        </div>
      </div>

      {/* Contact ROW  Area  */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </>
  );
};

export default ContactUs;
