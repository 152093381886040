import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ProductCard = ({ product, onClick }) => {
  return (
    <div className="product-card" onClick={onClick}>
      <img
        src={`${process.env.REACT_APP_SERVER_Local}/uploads/products/${product.imageUrl}`}
        alt={product.name}
        className="product-image"
      />
      <h3 className="product-name">{product.name}</h3>
      <div className="moreProductBtnWrapper">
        <Link to="/products" className=" learnmoreProductBtn text-center">
          Learn More
        </Link>
      </div>
    </div>
  );
};

export default ProductCard;
