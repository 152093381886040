import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import TextGenerate from "../../../TextGenerate/index";
import exportimg from "../../../assets/images/export.png";
import LocalTrading from "../../../assets/images/localBusiness.png";
import onlinebusiness from "../../../assets/images/onlinebusiness.png";
import impdist from "../../../assets/images/impdist.jpg";
import lifstylebrand from "../../../assets/images/lifestyle.gif";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

Modal.setAppElement("#root");

const WhatWeDo = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [items, setItems] = useState([]);

  // const items = [
  //   {
  //     bg: "#005825",
  //     direction: "rightLine",
  //     image: exportimg,
  //     title: "Export",
  //     description:
  //       "Farmvaly Bangladesh is a leading exporter of high-quality agricultural products from Bangladesh. We connect local farmers and producers with customers around the world. With years of experience and a commitment to excellence, we offer a wide variety of premium products that meet international standards.",
  //     link: "/our-business/export",
  //   },
  //   {
  //     bg: "#86B22B",
  //     direction: "leftLine",
  //     image: LocalTrading,
  //     title: "Local Trading",
  //     description:
  //       "Farmvaly Bangladesh is proud to be a trusted name in Bangladesh's domestic agricultural market. With deep roots in local farming communities, we bring high-quality agricultural products directly to businesses and consumers nationwide. By connecting local farmers with the market, we ensure fresh, premium products are available to meet the diverse needs of our customers.",
  //     link: "/our-business/local-trading",
  //   },
  //   {
  //     bg: "#9AC726",
  //     direction: "rightLine",
  //     image: onlinebusiness,
  //     title: "Online Business",
  //     description:
  //       "Our online platform offers a convenient way to shop for premium agricultural products. From fresh meat and oilseeds to pulses, spices, and more, you can easily find and order everything you need online.",
  //     link: "/our-business/online-business",
  //   },
  //   // {
  //   //   bg: "#FFFFFF",
  //   //   direction: "leftLine",
  //   //   image: impdist,
  //   //   title: "Import & Distribution",
  //   //   description:
  //   //     "Farmvaly Bangladesh is your gateway to the global agricultural market. Our Import & Distribution Business connects you with the finest international suppliers, offering a diverse range of premium-quality products tailored to meet the specific needs of businesses across Bangladesh. We source products from around the world, ensuring that you have access to the latest innovations and the highest quality standards.",
  //   //   link: "/our-business/import-distribution",
  //   // },

  //   // {
  //   //   bg: "#28a745",
  //   //   direction: "leftLine",
  //   //   image: ourfarmhouse,
  //   //   title: "Our Farmhouse",
  //   //   description:
  //   //     "At Farmvaly Bangladesh, we offer a unique opportunity for individuals and businesses to invest in sustainable farming through our Our Farmhouse program. This innovative model allows you to become a part of Bangladesh's thriving agricultural sector while enjoying the benefits of your investment. Whether you're passionate about supporting local farmers or seeking a rewarding investment, Our Farmhouse provides the perfect solution.",

  //   //   link: "/our-business/our-farmhouse",
  //   // },

  //   {
  //     bg: "#28a745",
  //     direction: "leftLine",
  //     image: lifstylebrand,
  //     title: "Lifestyle Brand",
  //     description:
  //       "MOVR, a lifestyle brand under the Farmvaly Bangladesh umbrella, embodies a fusion of modern trends and everyday practicality. Catering to a dynamic and fashion-conscious audience, MOVR offers an eclectic range of products, from tech accessories like data cables, chargers, and earbuds, to stylish apparel including T-shirts, sneakers, and sunglasses. The brand represents a balance between innovation and style, aiming to enhance daily living with products that reflect individuality, quality, and functionality. MOVR stands for movement, versatility, and modern living, designed for those who are constantly on the go yet value comfort and aesthetics.",

  //     link: "/our-business/lifestyle-brand",
  //   },
  // ];

  useEffect(() => {
    const getSections = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_Local}/whatwedo`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const dataresponse = await response.json();
        const productsData = dataresponse.data;

        setItems(productsData);
      } catch (err) {
        toast.error(err.message);
      }
    };

    getSections();
  }, []);

  const openModal = (item) => {
    setIsModalOpen(true);
    setSelectedItem(item);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <React.Fragment>
      <div className="about-wrapper whatwedoWrapper text-center">
        <div className="container">
          <motion.h2
            initial={{ y: 90, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0, duration: 0.5 }}
            className="title HEADtitle"
          >
            {"What We Do"}
          </motion.h2>
          {items.map((item, index) => (
            <div
              className={`row whatwedorow row--35 ${
                index % 2 === 1 ? "flex-row-reverse" : ""
              }`}
              key={index}
            >
              <motion.div
                initial={{ y: 90, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0, duration: 0.5 }}
                className={`${item.direction} col-lg-5 col-md-12`}
              >
                <div className="thumbnail">
                  <img
                    className="w-100"
                    src={`${process.env.REACT_APP_SERVER_Local}/uploads/whatwedo/${item.imageUrl}`}
                    alt="Image"
                  />
                </div>
              </motion.div>

              <motion.div
                initial={{ y: 90, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0, duration: 0.5 }}
                className={`col-lg-7 col-md-12 whatwedotextbox asdf-${index}`}
                onClick={() => openModal(item)}
                onMouseEnter={() => setHoveredItem(index)}
                onMouseLeave={() => setHoveredItem(null)}
                style={{
                  backgroundColor: hoveredItem === index ? item.bg : "initial",
                  transition: "background-color 0.3s ease",
                }}
              >
                <div className="about-inner inner">
                  <div className={`${item.direction}`}>
                    <h3 className="title">{item.title}</h3>
                    <p className="description">
                      <TextGenerate text={item.details} />
                    </p>

                    <div className="moreProductBtnWrapper">
                      <Link
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "start",
                        }}
                        to={item.link}
                        className="moreProductBtn text-center"
                      >
                        Details
                      </Link>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for displaying content */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="What We Do Details Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            zIndex: "9999",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -43%)",
            padding: "20px",
            borderRadius: "10px",
            width: "90%",
            maxWidth: "800px",
            height: "auto",
            maxHeight: "80vh",
            background: "#fff",
            overflowY: "auto",
          },
        }}
      >
        {selectedItem && (
          <div className="whatwedotextboxModalwrapper">
            <div className="whatwedotextboxModalImageDiv">
              <img
                className=""
                src={`${process.env.REACT_APP_SERVER_Local}/uploads/whatwedo/${selectedItem.imageUrl}`}
                alt={selectedItem.title}
                style={{ maxWidth: "400px" }}
              />
            </div>

            <div>
              <h3 className="title whatwedotextboxModalh3">
                {selectedItem.title}
              </h3>
              <p
                className="whatwedotextboxModalp"
                dangerouslySetInnerHTML={{ __html: selectedItem.details }}
              ></p>

              <div className="moreProductBtnWrapper">
                <Link
                  style={{
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "start",
                  }}
                  to={selectedItem.link}
                  className="moreProductBtn text-center"
                >
                  Details
                </Link>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default WhatWeDo;
