import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { FaFacebookF, FaLinkedinIn, FaEnvelope } from "react-icons/fa";
import { fetchMembers } from "../data/dataFetcher";

const TeamTwo = () => {
  const [memberList, setMemberList] = useState([]);

  useEffect(() => {
    const getAllMember = async () => {
      try {
        const allMember = await fetchMembers();
        setMemberList(allMember);
      } catch (err) {
        console.error("Error fetching the Member:", err);
      }
    };
    getAllMember();
  }, []);

  return (
    <div className="team-wrapper">
      {memberList.map((value, i) => (
        <motion.div
          initial={{ y: 190, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ delay: i * 0.3, duration: 1.5 }}
          className="team-card"
          key={`${i}-${value.name}`}
        >
          <div className="card-inner">
            <div className="card-front">
              <div
                className="thumbnail"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_SERVER_Local}/uploads/team/${value.image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
              <h3 className="team-name">{value.name}</h3>
              <p className="designation">{value.position}</p>
            </div>
            <div className="card-back">
              <div className="content">
                <h4 className="title">{value.name}</h4>
                <p className="designation">{value.position}</p>
                <ul className="social-list">
                  {value.linkedin_url && (
                    <li className="social-item">
                      <a href={value.linkedin_url} className="social-link" target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  )}
                  {value.email_url && (
                    <li className="social-item">
                      <a href={`mailto:${value.email_url}`} className="social-link">
                        <FaEnvelope />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default TeamTwo;
