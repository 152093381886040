import React from "react";
import ourfarmhouse from "../assets/images/ourfarmhouse.jpg";
import imageback from "../assets/images/imageback.jpg";
import { FaCheck } from "react-icons/fa";
import Header from "../component/header/Header";
import { LeafTitle } from "../component/common/LeafTitle";
import { motion } from "framer-motion";
import checkicon from "../assets/images/icons/check.png";
import SliderImage from "../component/slider/SliderImage";
import { GiChicken } from "react-icons/gi";
import { FaSeedling,FaTshirt } from "react-icons/fa";
import { GiTropicalFish } from "react-icons/gi";
import { GiTomato } from "react-icons/gi";
import { GiOpenedFoodCan } from "react-icons/gi";
import charger from "../assets/images/charger.png";
import snickers from "../assets/images/snickers.png";
import { MdSmartphone, MdHeadset,MdWatch} from "react-icons/md";

const whyinvest = [
  {
    title: "Hands-Free Farming",
    description:
      "Farmvaly manages everything from sourcing and feeding to harvesting and distribution. As an investor, you can relax while we maximize your returns.",
  },
  {
    title: "Quality Farming Practices",
    description:
      "We prioritize sustainable and ethical farming methods, ensuring a positive impact on the environment and the agricultural community.",
  },
  {
    title: "Transparent Operations & Monitoring",
    description:
      "Our online portal provides detailed updates on your investment, allowing you to track progress and stay informed.",
  },
  {
    title: "Strong Returns on Investment",
    description:
      "With Farmvaly's expertise and commitment to quality, investors can expect competitive returns. The high demand for locally produced, ethically farmed products drives growth potential.",
  },
  {
    title: "Support Local Farmers & Communities",
    description:
      "Invest in Our Farmhouse and directly support local farmers, contributing to the economic growth of rural communities while promoting sustainable farming practices and fair trade opportunities.",
  },
];

const LifestyleBrand = () => {
  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />

      <SliderImage title="Lifestyle Brand" />

      <div className="lifestylebrandTextWrapper">
        <div className="container">
          <div className="lifestylebrandText">
            <h2>
              MOVR, a lifestyle brand under the Farmvaly Bangladesh umbrella,
              embodies a fusion of modern trends and everyday practicality.
              Catering to a dynamic and fashion-conscious audience, MOVR offers
              an eclectic range of products, from tech accessories like data
              cables, chargers, and earbuds, to stylish apparel including
              T-shirts, sneakers, and sunglasses. The brand represents a balance
              between innovation and style, aiming to enhance daily living with
              products that reflect individuality, quality, and functionality.
              MOVR stands for movement, versatility, and modern living, designed
              for those who are constantly on the go yet value
              comfort and aesthetics.
            </h2>
          </div>

          <div className="upcomingProductCardWrapper" style={{marginTop:"40px"}}>
            <div className="upcomingProductCard">
              <MdSmartphone style={{ fontSize: "42px" }} />
              <h4>4G Phone</h4>
            </div>
            <div className="upcomingProductCard">
              <MdHeadset style={{ fontSize: "42px" }} />
              <h4>Headphone</h4>
            </div>
            <div className="upcomingProductCard">
              <img src={charger} alt="" style={{ maxWidth: "42px" }} />
              <h4>Charger/Adapter</h4>
            </div>
            <div className="upcomingProductCard">
              <MdWatch style={{ fontSize: "42px" }} />
              <h4>Smart Watch</h4>
            </div>
            <div className="upcomingProductCard">
              <FaTshirt style={{ fontSize: "42px" }} />
              <h4>T-Shirt</h4>
            </div>
            <div className="upcomingProductCard">
              <img src={snickers} alt="" style={{ maxWidth: "42px" }} />

              <h4>Snickers</h4>
            </div>
          </div>
        </div>
      </div>

      <div className="call-to-action-wrapper call-to-action text-white-wrapper  ptb--120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="inner text-center">
                <h2>Join Our Farmhouse Today</h2>
                <a className="rn-button-style--2" href="/contact">
                  <span>Contact Us</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LifestyleBrand;
