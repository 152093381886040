import React, { useEffect, useState } from "react";

let timer;
export default function TextGenerate({ text: propText }) {
  const [text, setText] = useState("");

  useEffect(() => {
    if (!propText) return;
    let i = -1;
    timer = setInterval(() => {
      i++;
      if (i === propText.length - 1) {
        clearInterval(timer);
      }
      setText((prev) => prev + (propText[i] || ""));
    }, 20);

    return () => {
      clearInterval(timer);
    };
  }, [propText]);

  return <div  dangerouslySetInnerHTML={{ __html: text }} ></div>;
}
