import React, { useState } from "react";
import DOMPurify from "dompurify";

const ProductDetails = ({ product, openQuoteModal }) => {
  const [mainImage, setMainImage] = useState(product?.imageUrl[0]);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  if (!product) {
    return <div>Product not found</div>;
  }

  const handleImageClick = (image, index) => {
    setMainImage(image);
    setActiveImageIndex(index);
  };

  // Helper function to sanitize HTML content with DOMPurify
  const sanitizeHtml = (htmlContent) => DOMPurify.sanitize(htmlContent);

  return (
    <>
      <div className="product-details-wrapper">
        <div className="product-details-image-container">
          <img
            src={`${process.env.REACT_APP_SERVER_Local}/uploads/products/${product.imageUrl}`}
            alt="Product"
          />
        </div>
        <div className="product-details-info">
          <div className="productNameDesc">
            <h2>{product.name}</h2>
            {/* Sanitize and render HTML content */}
            <p
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(product.details),
              }}
            ></p>
          </div>

          <button
            onClick={() => openQuoteModal(product.name)}
            className="getaQuaoteModalButton"
          >
            Submit a quote
          </button>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
