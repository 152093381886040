export const slideSlick = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  arrows: false,
  fade: true,
  easing: "fade",
  adaptiveHeight: true,
  autoplay: true,
  autoplaySpeed: 2800,
  pauseOnHover: false,
};
