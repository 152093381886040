import React, { useState, useEffect } from "react";
import ProductCard from "../component/product/ProductCard";
import Modal from "react-modal";
import ProductDetails from "../component/product/ProductDetails";
import ContactForm from "../elements/contact/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { motion } from "framer-motion";
import "../assets/css/products.css";
import { LeafTitle } from "./../component/common/LeafTitle";
import Header from "../component/header/Header";
import SliderImage from "../component/slider/SliderImage";

Modal.setAppElement("#root");

const Products = () => {
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const [isQuoteModalOpen, setIsQuoteModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [quoteProductName, setQuoteProductName] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch products from the server
  useEffect(() => {
    const getProducts = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_Local}/products`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const dataresponse = await response.json();
        const productsData = dataresponse.data;
        setProducts(productsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    getProducts();
  }, []);

  const openProductModal = (product) => {
    setIsProductModalOpen(true);
    setSelectedProduct(product);
  };

  const closeProductModal = () => {
    setIsProductModalOpen(false);
    setSelectedProduct(null);
  };

  const openQuoteModal = (productName) => {
    setIsQuoteModalOpen(true);
    setQuoteProductName(productName);
  };

  const closeQuoteModal = () => {
    setIsQuoteModalOpen(false);
  };

  // Extract unique categories
  const uniqueCategories = [...new Set(products.map((product) => product.category))];

  return (
    <React.Fragment>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />

      <SliderImage title={"Our Products"} />

      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>Error: {error}</p>
      ) : (
        uniqueCategories.map((category) => (
          <motion.div
            key={category}
            initial={{ y: 200 }}
            whileInView={{ y: 0 }}
            transition={{ duration: 1 }}
            className="products-wrapper"
          >
            <LeafTitle title={category.charAt(0).toUpperCase() + category.slice(1)} />
            <div className="products-grid">
              {products
                .filter((product) => product.category === category)
                .map((product, index) => (
                  <ProductCard
                    key={index}
                    product={product}
                    onClick={() => openProductModal(product)}
                  />
                ))}
            </div>
          </motion.div>
        ))
      )}

      {/* Product Details Modal */}
      <Modal
        isOpen={isProductModalOpen}
        onRequestClose={closeProductModal}
        className="productsDetailsModal"
        overlayClassName="productsDetailscustomOverlay"
      >
        <button onClick={closeProductModal} className="CloseProductModalBtn">
          <FontAwesomeIcon icon={faTimes} />
        </button>

        {selectedProduct && (
          <ProductDetails
            product={selectedProduct}
            openQuoteModal={openQuoteModal}
          />
        )}
      </Modal>

      {/* Quote Modal */}
      <Modal
        isOpen={isQuoteModalOpen}
        onRequestClose={closeQuoteModal}
        className="quoteModalModal"
        overlayClassName="quoteModalcustomOverlay"
      >
        <button onClick={closeQuoteModal} className="CloseQuoteModalBtn">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 style={{ textAlign: "center" }}>Get a Quote</h2>
        <ContactForm productName={quoteProductName} />
      </Modal>
    </React.Fragment>
  );
};

export default Products;
