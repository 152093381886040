import React, { useState, useEffect, useRef } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const CounterHome = () => {
  const { t } = useTranslation();
  const [didViewCountUp, setDidViewCountUp] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const ref = useRef(null);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  const handleScroll = () => {
    if (ref.current) {
      setScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Check scroll position on mount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const Data = [
    {
      countNum: 5000,
      countSuffix: "+",
      countTitle: "Livestock Supplier",
    },
    {
      countNum: 6500,
      countSuffix: "+",
      countTitle: "Farmer's Network",
    },
    {
      countNum: 25,
      countSuffix: "+",
      countTitle: "Years Management Expertise",
    },
    {
      countNum: 4250,
      countSuffix: "+",
      countTitle: "Metric Ton Product Export Experience",
    },
    {
      countNum: 1.5,
      countSuffix: "M",
      countTitle: "Egg Production Monthly",
    },
  ];

  const containerVariants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const cardVariants = {
    offscreen: {
      y: 300,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.3,
        duration: 1,
      },
    },
  };

  const backgroundPositionY = Math.min(scrollY / 2, 160);

  return (
    <div className="counterTWOMAINDIV counterAbout">
      <motion.div
        className="counterROW container"
        style={{
          backgroundPosition: `center ${160 - backgroundPositionY}%`,
        }}
        initial="offscreen"
        whileInView="onscreen"
        viewport={{ once: true, amount: 0.8 }}
        variants={containerVariants}
        ref={ref}
      >
        {Data.map((value, index) => (
          <motion.div
            className="counterup_style--1 col-lg-3 col-md-4 col-sm-6 col-12"
            key={index}
            variants={cardVariants}
          >
            <h5 className="counter">
              <VisibilitySensor
                onChange={onVisibilityChange}
                offset={{ top: 10 }}
                delayedCall
              >
                <CountUp
                  end={didViewCountUp ? value.countNum : 0}
                  suffix={value.countSuffix}
                  decimals={value.countNum % 1 !== 0 ? 1 : 0}
                />
              </VisibilitySensor>
            </h5>
            <p className="description">
              {value.countTitle.split("\n").map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default CounterHome;
