import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import g1 from "../assets/images/gallery/Empowering Bangladesh's Agricultural Economy (2).jpg";
import g2 from "../assets/images/gallery/Extensive Product Range for Domestic Markets.jpg";
import g3 from "../assets/images/gallery/Global Reach with Local Roots (1).jpg";
import g4 from "../assets/images/gallery/Partnering with Local Farmers for a Sustainable Future (1).jpg";
import g5 from "../assets/images/gallery/Preserving Bangladesh's Agricultural Heritage.jpg";
import g6 from "../assets/images/gallery/Pulses (3).jpg";
import g7 from "../assets/images/gallery/Tailored Solutions for Your Market.jpg";
import g8 from "../assets/images/gallery/Tilapia.jpg";
import g9 from "../assets/images/gallery/Traceability and Compliance.jpg";
import g10 from "../assets/images/gallery/checking.jpg";
import g11 from "../assets/images/gallery/egggss.jpg";
import g12 from "../assets/images/gallery/farming.jpg";
import g13 from "../assets/images/gallery/poultry.jpg";
import g14 from "../assets/images/gallery/quality assure even localy.jpg";
import g15 from "../assets/images/gallery/rui.jpg";
import g16 from "../assets/images/gallery/smart-agriculture-farming-market-768x480.jpg";
import g17 from "../assets/images/gallery/working farmer.jpg";
import g18 from "../assets/images/gallery/working.jpg";

const TabOne = [
  { image: g1, bigImage: g1 },
  { image: g2, bigImage: g2 },
  { image: g3, bigImage: g3 },
  { image: g4, bigImage: g4 },
  { image: g5, bigImage: g5 },
  { image: g6, bigImage: g6 },
  { image: g7, bigImage: g7 },
  { image: g8, bigImage: g8 },
  { image: g9, bigImage: g9 },
  { image: g10, bigImage: g10 },
  { image: g11, bigImage: g11 },
  { image: g12, bigImage: g12 },
  { image: g13, bigImage: g13 },
  { image: g14, bigImage: g14 },
  { image: g15, bigImage: g15 },
  { image: g16, bigImage: g16 },
  { image: g17, bigImage: g17 },
  { image: g18, bigImage: g18 },
];

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tab1: 0,
      isOpen: false,
      currentPage: 1,
      imagesPerPage: 6,
    };
  }

  handleClick = (number) => {
    this.setState({ currentPage: number });
  };

  render() {
    const { tab1, isOpen, currentPage, imagesPerPage } = this.state;

    // Logic for displaying images
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = TabOne.slice(indexOfFirstImage, indexOfLastImage);

    // Logic for rendering page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(TabOne.length / imagesPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div>
        {/* Start Portfolio Area */}
        <div className="rn-portfolio-area ptb--120 bg_color--1 line-separator">
          <div className="container">
            <div className="row justify-content-center align-items-center">
              {currentImages.map((value, index) => (
                <div
                  className="col-lg-3"
                  key={index}
                  style={{ margin: "10px" }}
                >
                  {isOpen && (
                    <Lightbox
                      mainSrc={TabOne[tab1].bigImage}
                      nextSrc={TabOne[(tab1 + 1) % TabOne.length]}
                      prevSrc={
                        TabOne[(tab1 + TabOne.length - 1) % TabOne.length]
                      }
                      onCloseRequest={() => this.setState({ isOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          tab1: (tab1 + TabOne.length - 1) % TabOne.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          tab1: (tab1 + 1) % TabOne.length,
                        })
                      }
                    />
                  )}
                  <div className="item-portfolio-static">
                    <div
                      onClick={() =>
                        this.setState({ isOpen: true, tab1: index })
                      }
                    >
                      <div className="portfolio-static">
                        <div className="thumbnail-inner">
                          <div className="thumbnail">
                            <a href="#gallery">
                              <img src={value.image} alt="Portfolio Images"  style={{width:"285px",height:"200px"}}/>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* Pagination Controls */}
            <div className="pagination" style={styles.paginationContainer}>
              {pageNumbers.map((number) => (
                <button
                  key={number}
                  onClick={() => this.handleClick(number)}
                  className={currentPage === number ? "active" : ""}
                  style={{
                    ...styles.paginationButton,
                    ...(currentPage === number ? styles.activeButton : {}),
                  }}
                >
                  {number}
                </button>
              ))}
            </div>
          </div>
        </div>
        {/* End Portfolio Area */}
      </div>
    );
  }
}


const styles = {
  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "20px",
  },
  paginationButton: {
    margin: "5px",
    padding: "10px 15px",
    fontSize: "14px",
    cursor: "pointer",
    borderRadius: "5px",
    border: "1px solid #ddd",
    backgroundColor: "#f9f9f9",
    color: "#333",
    transition: "background-color 0.3s, color 0.3s",
  },
  activeButton: {
    backgroundColor: "#005825",
    color: "#fff",
    border: "1px solid #86B22B",
  },
};

export default Gallery;
