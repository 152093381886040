import React, { useEffect, useState } from "react";
import Header from "./../component/header/Header";
import { Link } from "react-router-dom";
import { ArticleList } from "../data/ArticleData";
import { LeafTitle } from "./../component/common/LeafTitle";
import SliderImage from "./../component/slider/SliderImage";
import { fetchArticles, fetchNews } from "../data/dataFetcher";

const Article = () => {
  const [newsData, setNewsData] = useState([]);
  const [articleData, setArticleData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getNews = async () => {
      setLoading(true);
      setError(null);
      try {
        const fetchedNewsData = await fetchNews();
        setNewsData(fetchedNewsData);
      } catch (err) {
        setError("Error fetching the news.");
        console.error("Error fetching the news:", err);
      } finally {
        setLoading(false);
      }
    };

    const getArticles = async () => {
      try {
        const fetchedArticleData = await fetchArticles();
        setArticleData(fetchedArticleData);
      } catch (err) {
        console.error("Error fetching the articles:", err);
      }
    };

    getNews();
    getArticles();
  }, []);

  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
      />

      <SliderImage title={"Article & News"} />

      {/* Article Section Start */}
      <div className="rn-blog-area bg_color--1">
        <div className="container">
          <div className="container articleTitle">
            <LeafTitle title={"Articles"} />
          </div>
          <div className="row">
            {articleData.map((article) => (
              <div
                className="col-lg-4 col-md-6 col-sm-6 col-12"
                key={article.id}
                style={{ margin: "10px 0px" }}
              >
                <div className="blog blog-style--1">
                  <div className="thumbnail">
                    <Link to={`/article-details/${article.id}`}>
                      <img
                        className="w-100"
                        src={`${process.env.REACT_APP_SERVER_Local}/uploads/articles/${article.image}`}
                        alt="Blog Images"
                      />
                    </Link>
                  </div>
                  <div className="content">
                    <p className="blogtype">{article.category}</p>
                    <h4 className="title">
                      <Link
                        to={`/article-details/${article.id}`}
                        style={{ color: "black" }}
                      >
                        {article.title}
                      </Link>
                    </h4>
                    <div className="blog-btn">
                      <Link
                        className="rn-btn"
                        to={`/article-details/${article.id}`}
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Article Section End */}

      {/* News Section Start */}
      <div className="rn-news-area bg_color--1">
        <div className="container">
          <div className="container articleTitle">
            <LeafTitle title={"News"} />
          </div>
          <div className="row">
            {loading ? (
              <p>Loading news...</p>
            ) : error ? (
              <p>{error}</p>
            ) : (
              newsData.map((news) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 col-12"
                  key={news.id}
                  style={{ margin: "10px 0px" }}
                >
                  <div className="blog blog-style--1">
                    <div className="thumbnail">
                      <Link to={`/news-details/${news.id}`}>
                        <img
                          className="w-100"
                          src={`${process.env.REACT_APP_SERVER_Local}/uploads/news/${news.image}`}
                          alt="Blog Images"
                        />
                      </Link>
                    </div>
                    <div className="content">
                      <p className="blogtype">{news.category}</p>
                      <h4 className="title">
                        <Link
                          to={`/news-details/${news.id}`}
                          style={{ color: "black" }}
                        >
                          {news.title}
                        </Link>
                      </h4>
                      <div className="blog-btn">
                        <Link
                          className="rn-btn"
                          to={`/news-details/${news.id}`}
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
      {/* News Section End */}
    </>
  );
};

export default Article;
