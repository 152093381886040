import React, { Component } from "react";
import ContactForm from "./ContactForm";

import about from "../../assets/images/contactus.jpg";

class ContactTwo extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-end">
            <div className="col-lg-6 order-1 order-lg-1">
              <div className="contactImage">
                <img src={about} alt="img" />
              </div>
            </div>
            <div className="col-lg-6 order-2 order-lg-2">
              <div className=" text-left mb--30">
                <h2 className="">Contact Us</h2>
                <p className="description">
                  We're here to help! Contact our support team for assistance
                  with your order, questions about our products, services or
                  general inquiries.
                </p>
              </div>
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
