import React, { useState } from "react";
import {
  FiCast,
  FiLayers,
  FiUsers,
  FiMonitor,
  FiChevronUp,
} from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { LeafTitle } from "./../component/common/LeafTitle";
import HeroSlider from "../component/slider/HeroSlider";
import bangladeshVid from "../assets/images/bangladesh.mp4";
import AtaGlance from "./../component/common/AtaGlance";
import "../assets/css/bangladesh.css";

import Header from "../component/header/Header";
import PageHelmet from "../component/common/Helmet";
Modal.setAppElement("#root");

const Bangladesh = () => {
  const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const ServiceList = [
    {
      icon: <FiCast />,
      title: "Roots of Traditional Farming",
      color: "#015a27",
      description:
        "Bangladeshi farmers relied heavily on age-old techniques passed down through generations. The use of simple tools and natural irrigation methods characterized this era of subsistence farming. Rice paddies dominated the landscape, with jute serving as the country’s golden fiber, driving the economy during the mid-20th century. While these methods were effective in feeding the population, they limited the potential for large-scale commercial agriculture and exports.",
    },
    {
      icon: <FiLayers />,
      title: "Shift Towards Commercial Agriculture",
      color: "#015a27",
      description:
        "The transformation began in the late 20th century when the government recognized the need to modernize agriculture to meet the growing demands of both the local and global markets. Initiatives were launched to introduce high-yielding crop varieties, modern irrigation techniques, and better farming equipment. This shift not only increased productivity but also set the stage for Bangladesh to enter the global agricultural market. The real breakthrough came with the diversification into new agricultural products. Vegetables, fruits, spices, and dry foods were added to the export list, opening up new avenues for farmers. The introduction of aquaculture and the subsequent export of frozen fish and shrimp further solidified Bangladesh’s position in the global market.",
    },
    {
      icon: <FiUsers />,
      title: "Innovation Drives Growth",
      color: "#015a27",
      description:
        "Innovation has been at the heart of Bangladesh’s agricultural evolution. The adoption of new technologies, such as improved seed varieties, mechanized farming, and the use of fertilizers and pesticides, has significantly boosted crop yields. In recent years, the focus has shifted towards sustainable farming practices, including organic farming and integrated pest management, which have opened up premium markets abroad. The establishment of cold storage facilities, improved transportation networks, and better access to credit for farmers have all contributed to reducing post-harvest losses and ensuring the quality of export products. Moreover, the government’s investment in research and development has led to the creation of value-added products, allowing Bangladesh to move up the value chain in agricultural exports.",
    },
    {
      icon: <FiMonitor />,
      title: "Global Expansion",
      color: "#015a27",
      description:
        "Strategic partnerships have played a crucial role in Bangladesh’s ascent as a global agricultural exporter. Collaborations with international organizations, such as the Food and Agriculture Organization (FAO) and the World Bank, have provided technical assistance and funding for agricultural development projects. These partnerships have helped improve farming practices, enhance food safety standards, and expand market access. Bangladesh has also forged strong trade relationships with countries across Asia, Europe, and the Americas. These trade agreements have facilitated the export of over 700 agricultural products, including high-demand items like frozen fish, shrimp, tea, and spices. The involvement of the private sector, particularly through public-private partnerships, has further accelerated the growth of agricultural exports.",
    },
    {
      icon: <FiCast />,
      title: "Rising Leader in Global Agriculture",
      color: "#015a27",
      description:
        "Today, Bangladesh stands as a key global player in agricultural exports, with its products reaching markets in over 140 countries. The resilience of the sector, as evidenced by the recent recovery in export earnings, highlights the country’s ability to adapt to challenges, such as supply chain disruptions and currency fluctuations. Bangladesh’s agricultural evolution is a testament to the power of innovation, strategic partnerships, and a commitment to sustainable growth. As the country continues to explore new markets and develop high-quality products, its role in the global agricultural landscape is set to become even more prominent. The journey from traditional farming to becoming an export powerhouse reflects Bangladesh’s determination to not only feed its people but also to share its agricultural wealth with the world.",
    },
  ];

  const openServiceModal = (service) => {
    setSelectedService(service);
    setIsServiceModalOpen(true);
  };

  const closeServiceModal = () => {
    setIsServiceModalOpen(false);
    setSelectedService(null);
  };

  return (
    <>
      <Header
        headerPosition="header--fixed sticky logoresize"
        logo="all-dark"
        color="color-black"
        textColor="white"
      />
      <PageHelmet pageTitle="Bangladesh" />
      <HeroSlider video={bangladeshVid} />

      <div className="bangldeshEvolution">
        <div className="container">
          <LeafTitle title={"Bangladesh's Agricultural Evolution"} />
          <p>
            Bangladesh’s agricultural sector has a rich history rooted in
            traditional farming practices. The fertile lands of Bangladesh have
            long been known for their diverse crops, including rice, jute, tea,
            and various fruits and vegetables. Bangladesh’s agricultural output
            was primarily focused on meeting domestic needs, with little
            emphasis on exports.
          </p>
        </div>
      </div>
      {/* Start Service Area */}
      <div className="heritagebd ptb--80 bg_color--5">
        <div className="container">
          <div className="row service-one-wrapper">
            {ServiceList.map((val, i) => (
              <div
                className="col-xxl-2 col-xl-2 col-lg-4 col-md-6 col-sm-6 col-12"
                key={i}
                style={{margin:"0px 10px"}}
              >
                <div
                  className="bangldeshService"
                  onClick={() => openServiceModal(val)}
                >
                  <h3 className="text-center">{val.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* End Service Area */}

      <div className="bangldeshAtaglancewrapper">
        <div className="container">
          <LeafTitle title={"Bangladesh at a glance"} />
        </div>

        <AtaGlance />
      </div>

      {/* Start Service Modal */}
      <Modal
        isOpen={isServiceModalOpen}
        onRequestClose={closeServiceModal}
        contentLabel="Service Details Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
          content: {
            zIndex: "9999",
            top: "55%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            borderRadius: "10px",
            width: "80%",
            maxWidth: "800px",
            background: "#fff",
          },
        }}
      >
        {selectedService && (
          <div>
            <h2>{selectedService.title}</h2>
            <p>{selectedService.description}</p>
          </div>
        )}

        <button className="modal-close-btn" onClick={closeServiceModal}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </Modal>
      {/* End Service Modal */}

      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </>
  );
};

export default Bangladesh;
