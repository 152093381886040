import React from "react";
import logo from '../../assets/images/logo/logo.png'

const Divider = () => {
  return (
    <div class="h-divider">
      <div class="shadow"></div>
      <div class="text2">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

export default Divider;
